.Image {
	position: relative;
	overflow:  hidden;
}
.Image > img {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}
.Image.hover:hover {
	cursor: pointer;
}