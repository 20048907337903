* {
  box-sizing: border-box;

  /* --background: rgb(251,250,226); */
  --background: rgb(255,255,255);
  --white: rgb(255,255,255);
  --dark: rgb(100,100,100);
  --purple: rgb(41,54,93);
  --link: rgb(0,100,255);
  /* --primary: rgb(126,232,151); */
  --primary: rgb(230,230,230);
  --normal-button: rgb(230,230,230);
  --normal-button-text: rgb(0,0,0);
  --hover-button: rgb(60,60,60);
  --hover-button-text: rgb(230,230,230);

  --h1:56px;
  --h2:40px;
  --h3:32px;
  --h4:24px;
  --h5:20px;
  --h6:18px;
  --p:16px;
  --h7:12px;
  --h8:8px;
}

html {
  padding:0;
  margin:0;
  width:calc(100vw - 16px);
  font-family: Tahoma, Arial, sans-serif;
  font-size:16px;
}

body {
  position:relative;
  background-color: var(--background);
}

h1, h2, h3, h4, h5, h6, p {    margin:0;   }
h1, .h1 {    font-size:var(--h1);   font-weight:550;    }
h2, .h2 {    font-size:var(--h2);   font-weight:550;    }
h3, .h3 {    font-size:var(--h3);   font-weight:550;    }
h4, .h4 {    font-size:var(--h4);   font-weight:550;    }
h5, .h5 {    font-size:var(--h5);   font-weight:550;    }
h6, .h6 {    font-size:var(--h6);   font-weight:550;    }
.h7 {   font-size:var(--h7); }
.h8 {   font-size:var(--h8); }

hr {
  margin: 0;
  width: 100%;
}
hr.faded {
  border-color: rgba(240,240,240,0.25);
}
.no-br, .nobr {
  white-space: nowrap;
}
ul, ol {
  margin: 0;
  padding-left: 28px;
}
a, .a {
  color: var(--link);
}

p, li {
  line-height: 1.5em;
}


.App {
  position: relative;
  width: 100%;
  padding-left: 240px;
  box-sizing: border-box;
}
.App.Mobile {
  padding-left: 0;
  margin-top: 180px;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;

  width:100%;
  padding: 48px;
}
.Mobile main {
  padding: 24px;
}