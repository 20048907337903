.HomeContent {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.HomeContent .HomeItem {
	width: 100%;
}
.HomeLinks {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.HomeLinks .HomeLink {
	width: 30%;
	text-align: center;
}

.Skills {
	width: 100%;
	/* transform: translateY(-16px); */

	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: stretch;
	flex-wrap: wrap;
	grid-gap: 32px;
}
.SkillGroup {
	width: 100%;
}
.SkillComponent {
	width: 30%;
	flex-grow: 1;
	margin: 0;
	padding: 0px;
	list-style: none;

	box-shadow:0px 0px 4px 2px rgba(0,0,0,0.1);
	border-radius: 16px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}
.SkillComponent .SkillGroup {
	margin-bottom: 16px;
	padding-left: 32px;
	padding-right: 32px;
	box-sizing: border-box;
	border-radius: 8px;
	font-size: 0.8em;

	flex-basis: max-content;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.SkillComponent .SkillGroup:first-child {
	padding-top: 32px;
}
.SkillComponent .SkillGroup:last-child {
	padding-bottom: 32px;
}
.SkillComponent.SkillLanguages {
	background-color: rgba(145, 198, 245, 0.15);
}
.SkillComponent.SkillFrameworks {
	background-color: rgba(255, 189, 49, 0.15);
}
.SkillComponent.SkillSoftware  {
	background-color: rgba(120, 120, 120, 0.15);
}
.SkillComponent .SkillGroup .SkillList {
	list-style: none;
	padding: 0;
	margin-top: 4px;
	margin-bottom: 4px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
	grid-gap: 8px;
}
.SkillComponent .SkillGroup .SkillList .Skill {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
}
.SkillComponent .SkillGroup .SkillList .Skill .SkillIcon {
	background-color:rgb(240,240,240);
	padding:10px;
	border: 1px solid rgba(20,20,20,0.1);
}

.Education ul {
	list-style: none;
	padding: 0px;
}
.Education ul li {
	margin-bottom: 48px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;	
	flex-wrap: nowrap;
	grid-gap: 24px;
}
.Education .EducationLogo {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	grid-gap: 24px;
}
.Education .EducationLocation {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	flex-wrap: wrap;
	grid-gap: 8px;
}
.Education .EducationLocation p {
	font-size: 0.8em;
}
.Education .DatesContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	grid-gap: 16px;
	flex-wrap: nowrap;
}
	.Education .DatesContainer .DateStatus {
		width: max-content;
		padding: 8px 16px 8px 16px;
		border-radius: 8px;
		font-size: 0.8em;
	}
	.Education .DatesContainer .DateStatus.Current {
		background-color: blue;
		color: white;
	}
	.Education .DatesContainer .DateStatus.OnHold {
		background-color: yellow;
	}
	.Education .DatesContainer .DateStatus.Completed {
		background-color: green;
		color: white;
	}

	.Skills.Mobile {
		width: 70%;
		min-width: 200px;
		margin: auto;
	}
	.Skills.Mobile .SkillComponent {
		width: 100%;
		flex-basis: 100%;
	}