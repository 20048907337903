.AppHeader {
  position:fixed; 
    left:0; top:0;
    width:240px; height:100vh;
  z-index:1000;
  background-color:rgb(0,0,0,0.2);
  transition: left 0.25s ease-in-out, width 0.25s ease-in-out;
}
.Mobile .AppHeader {  
  width:100vw;  height:180px; 
}

.AppNav {
  display:flex; 
    flex-direction:column; justify-content:flex-start;  align-items:flex-start;
  position:relative;  
    width:100%; height:100%;  padding:16px;
  background-color: var(--background);
}
  .AppNav a { 
    text-decoration:none;  color:initial;
  }
.Mobile .AppNav {
  flex-direction:row;  align-items:center;
  box-shadow: 2px 0px 10px 5px rgba(0,0,0,0.2);
}

.NavProfile {
  position:relative;
    width:auto; margin-top:16px; margin-bottom:32px;
  display:flex;
    flex-direction:column;  justify-content:flex-start; align-items:flex-start;
}
  .NavProfile .ProfileImageWrapper {
    position:relative;
      width:80px; height:80px;
      margin-right:16px;
  }
    .NavProfile .ProfileImageWrapper a {
      text-decoration: none;
    }
    .NavProfile .ProfileImageWrapper .ProfileImageOverlay {
      position:absolute;
        left:75%; top:15%;
      transform:translateY(-50%) rotate(15deg);
      color:white;
      text-align: center;
    }
    .NavProfile .ProfileSocialLinksContainer {
      display:flex;
        flex-direction:row;  justify-content:flex-start;  flex-wrap:wrap;
      text-align: center;
      margin-top: 4px;
    }
    .NavProfile .ProfileSocialMediaIcon {
      margin-right:8px;
    }
    .NavProfile .ProfileSocialMediaIcon:hover {
      cursor:pointer;
      opacity:0.8;
    }
.Mobile .NavProfile {
  margin:0;
}

.NavLinks {
  position: relative;
  width: 100%;
}
  .NavLinksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }
  .NavItem {
    width: 100%;
    padding: 8px 16px 8px 16px;
    text-decoration: none;
    margin-bottom: 4px;
    border-left: 2px solid var(--normal-button);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }
  .NavItem.CurrentLink {
    background-color: rgb(235,235,235);
  }
  .NavItem.DownloadLink {
    background-color: rgb(202,240,255);
  }
  .NavLinksWrapper a:last-child .NavItem {
    border-right: none;
  }
    .NavItem .NavItemDescription {
      position: absolute;
      top: calc(100% + 6px);
      right: 0;
      opacity: 0;
      font-style: italic;
    }
    .NavItem:hover .NavItemDescription {
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
    }
    .NavItem .NavIcon {
      width: 16px;
      padding-right: 4px;
    }
.Mobile .NavLinks {
  margin-left: 16px;
}
.Mobile .NavItemDescription {
  display:none;
}
