div.Date {
	text-align: center;
	box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
	width: min-content;
	min-width: 80px;
}
div.Date p {
	margin: 0px;
	line-height: 16px;
}
div.Date .DateTop {
	padding: 4px;
	box-sizing: border-box;
	background-color: #ef4136;
	color: white;
}
div.Date .DateBottom {
	padding: 8px;
	box-sizing: border-box;
}