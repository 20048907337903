.PortfolioHeader {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
	.PortfolioHeader .PortfolioHeaderLinks {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
	}
	.PortfolioHeader .PortfolioHeaderOtherLinks {
		flex-shrink: 0;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		grid-column-gap: 16px;
	}
	.PortfolioHeader .PortfolioHeaderOtherLinks .PortfolioCurrentOtherLink {
		opacity: 0.5;
	}
.Portfolio {
	width: 100%;
	padding-bottom: 32px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
}
.PortfolioPages {
	width: 100%;
	flex-shrink: 1;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
}
.PortfolioPages .PortfolioPageLink {
	width: calc(100% - 16px);
	padding-left: 0px;
	box-sizing: border-box;
	transition: padding-left 0.25s ease-in-out;
}
.PortfolioPages .PortfolioPageLink a {
	text-decoration: none;
	color: initial;
}
.PortfolioPages .PortfolioPageLink h3 {
	color: var(--link);
}
.PortfolioPages .PortfolioPageLink:hover {
	cursor: pointer;
	padding-left: 16px;
}
.PortfolioResources {
	width: 240px;
	flex-shrink: 0;
	padding-left: 32px;
	padding-right: 32px;
	box-sizing: border-box;
}

.PortfolioItems {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;

	transition: width 0.25s ease-in-out;
}
.PortfolioItems a {
	color: initial;
	text-decoration: none;
}
.PortfolioRows {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: wrap;
}
.PortfolioRow {
	width: 100%;
}

.PortfolioMiniItem {
	position: relative;
	width: 100%;
	height: 32px;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;

}
.PortfolioItem {
	position: relative;
	width: 160px;
	height: auto;
	margin: 16px;
	text-align: center;
}
.PortfolioItem .PortfolioItemImageWrapper {
	position: relative;
	width: 160px;
	height: auto;
	box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
	padding: 16px;
	box-sizing: border-box;
	border-radius: 16px;
	transition: box-shadow 0.25s ease-in-out;
}
.PortfolioItem .PortfolioItemImage {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);

	width: 100%;
	max-height: 100%;
}
.PortfolioItem .linkSuggestion {
	color: var(--link);
	font-size: calc(var(--h7) * 0.9);
	transition: font-size 0.25s ease-in-out;
}
.PortfolioItem:hover {
	cursor: pointer;
}
.PortfolioItem:hover .PortfolioItemImageWrapper {
	box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
}
.PortfolioItem:hover .linkSuggestion {
	text-decoration: underline;
}

.PortfolioDisplay {
	height: 100%;
	box-sizing: border-box;

	transition-property: padding;
	transition-duration: 1s;
	transition-delay: 0s;
}
.PortfolioDisplay.enlargedPanel {
	padding: 0;
}
.PortfolioDisplayCover {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color:  rgb(130,130,130,0.5);
}
.PortfolioDisplayHeader {
	width: 100%;
	height: 32px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}
.PortfolioDisplayContents {
	flex-basis: 100%;
	flex-shrink: 1;
	overflow-y: auto;
}
.PortfolioDisplayPanel {
	width: 80%;
	min-width: 400px;
	margin: auto;
	height: 100%;
	padding: 32px 64px 32px 64px;
	overflow-y: auto;
	background-color:  white;
	box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.2);
	border-radius: 16px;
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;

	transition-property: width, height;
  	transition-duration: 1s;
  	transition-delay: 0s;
}
.PortfolioDisplayPanel.enlargedPanel {
	width: 100%;
	height: 100%;
}







.PortfolioContent {
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.Portfolio .HideInMobile {
	display: initial;
}
.Portfolio .ShowInMobile {
	display: none;
}
.PortfolioContent .PortfolioContentHeader {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderImage {
	flex-basis: 150px;
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 24px;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain {
	flex-basis: 100%;
	flex-shrink: 1;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain .PortfolioContentHeaderMainTable .PortfolioContentHeaderMainTableRow {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: nowrap;
	margin-bottom: 16px;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain .PortfolioContentHeaderMainTable .PortfolioContentHeaderMainTableRow .PortfolioContentHeaderMainTableRowKey {
	flex-basis: 100px;
	margin-right: 8px;
	flex-shrink: 0;
	flex-grow: 0;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain .PortfolioContentHeaderMainTable .PortfolioContentHeaderMainTableRow .PortfolioContentHeaderMainTableRowValue {
	flex-basis: 100%;
	flex-shrink: 1;
}
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain .PortfolioContentHeaderMainTable .PortfolioContentHeaderMainTableRow .PortfolioContentHeaderMainTableRowValue ul, 
.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderMain .PortfolioContentHeaderMainTable .PortfolioContentHeaderMainTableRow .PortfolioContentHeaderMainTableRowValue ol {
	margin: 0;
	padding-left: 16px;
}

.PortfolioContent .PortfolioThumbnail {
	margin-top: 2px;
	margin-left: 2px;
	box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
}
.PortfolioContent .PortfolioStatus {
	width: max-content;
	margin:auto;
	margin-top:-8px;
	text-align:center;
	padding: 8px 16px 8px 16px;
	border-radius: 8px;
	font-size: 0.8em;
}
.PortfolioContent .DatePeriodContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	grid-row-gap: 2px;
	margin-bottom: 8px;
}
	.PortfolioContent .DatePeriodContainer:last-child {
		margin-bottom: 2px;
	}
	.PortfolioContent .DatePeriodContainer .DateContainer {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
	}
	.PortfolioContent .DatePeriodContainer .DateContainer.WithHeader {
		padding-left: 8px;
		border-left: 2px solid rgb(240,240,240);
	}
.PortfolioContent .PortfolioStatus.Ongoing {
	background-color: blue;
	color: white;
}
.PortfolioContent .PortfolioStatus.OnHold {
	background-color: yellow;
}
.PortfolioContent .PortfolioStatus.Completed {
	background-color: green;
	color: white;
}
.PortfolioContent .PortfolioStatus.Deprecated {
	background-color: red;
	color: white;
}
.PortfolioContent .PortfolioLinksWrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.PortfolioContent .PortfolioMain .PortfolioContentImage {
	width: 50%;
	min-width: 300px;
	margin-left: 25%;
	margin-right: 25%;
}
.PortfolioContent .PortfolioMainBlock {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;
}
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockText {
		flex-basis: 100%;
		flex-shrink: 1;
	}
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockImageWrapper.Left {
		flex-basis: 320px;
		margin-right: 16px;
		margin-left: 0px;
	}
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockImageWrapper.Right {
		flex-basis: 320px;
		margin-left: 16px;
		margin-right: 0px;
	}
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockImageWrapper .PortfolioContentImage  {
		width: 100%;
		min-width: 0px;
		margin: 0px;
	}
	.PortfolioContent .PortfolioMainImageGallery {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		gap: 8px 8px;
		flex-wrap: wrap;
	}
	.PortfolioContent .PortfolioMainImageGallery .PortfolioContentImage  {
		flex-basis: 40%;
		margin-left: 0;
		margin-right: 0;
	}


.PortfolioContent .PortfolioContentColumns {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.PortfolioContent .PortfolioContentColumns .PortfolioContentColumn {
	width: 50%;
	padding-left: 8px;
	padding-right: 8px;
	box-sizing: border-box;
}
.PortfolioContent .PortfolioContentColumns .PortfolioContentColumn:first-child {
	padding-left: 0;
}
.PortfolioContent .PortfolioContentColumns .PortfolioContentColumn:last-child {
	padding-right: 0;
}
.PortfolioContent .PortfolioContentColumn .PortfolioContentImage {
	margin: 0;
}

.PortfolioContent figure {
	margin-left:auto;
	margin-right:auto;
	width: 90%;
	max-width:800px;
}
.PortfolioContent figure img {
	width:100%;
	border:  1px solid rgb(235,235,235);
}
.PortfolioContent figure figcaption {
	font-size: 0.8em;
	margin-top: 4px;
	font-style: italic;
}

.PortfolioContent blockquote.callout {
	border: none;
	padding: 16px;
	background-color: rgb(230,230,230);
	font-size: initial;
}

@media only screen and (max-width: 900px) {
	.PortfolioItems {
		justify-content: center;
	}

	.PortfolioDisplayPanel {
		padding: 24px;
	}


	.PortfolioContent {
		flex-direction: column;
		align-items: center;
	}
	.PortfolioDisplay .HideInMobile,  {
		display: none;
	}
	.PortfolioDisplay .ShowInMobile {
		display: initial;
	}

	.PortfolioContent .PortfolioContentHeader {
		flex-direction: column;
		align-items: center;
	}
	.PortfolioContent .PortfolioContentHeader .PortfolioContentHeaderImage {
		margin-right: 0px;
		margin-bottom: 24px;
	}

	.PortfolioContent .PortfolioMain .PortfolioMainBlock {
		flex-direction: column;
	}
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockImageWrapper.Left,
	.PortfolioContent .PortfolioMainBlock .PortfolioBlockImageWrapper.Right {
		flex-basis: 160px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 16px;
	}

	.PortfolioContent h3 {
		text-align: center;
		margin-bottom: 32px;
	}

	.PortfolioContent .PortfolioContentColumns {
		flex-direction: column;
		justify-content: flex-start;
	}
	.PortfolioContent .PortfolioContentColumns .PortfolioContentColumn {
		width: 100%;
	}
}

@media only screen and (max-width: 550px) {
	.Portfolio {
		flex-direction: column;
	}
	.PortfolioResources {
		margin-top: 32px;
		padding-left: 4px;
		padding-right: 4px;
		width: 100%;
	}
}

.PortfolioDisplay.Mobile {
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
}
.PortfolioDisplay.Mobile .PortfolioDisplayPanel {
	height: min-content;
	width: 100%;
	min-width: 0px;
	max-height: 100%;
	max-width: 100%;
	margin: 0;
	border-radius: 0px;
}